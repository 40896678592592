import React from 'react';

const SubdivisionCard = ({ subdivision, city }) => {
    return (
        <div className="col-md-2">
            <div className="card">
                {/* Placeholder image */}
                <img className="card-img-top" src="/subdivision.jpeg" alt="Subdivision" />
                <div className="card-body">
                    <h5 className="card-title"><a href={`/subdivision/${city}/${subdivision}`}>{subdivision}</a></h5>
                    {/* You can add additional information about the subdivision here */}
                </div>
            </div>
        </div>
    );
};

export default SubdivisionCard;
