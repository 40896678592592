const Contact = () => {
    return (
        <section className="contact">
            <div className="page-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <h1 className="page-title">Contact</h1>
                        <p>Discover the future of Real Estate with our AI-Powered Platform! Connect with an expert licensed real estate agent and find your Dream Home today.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="contact-item">
                                        <i className="fas fa-envelope"></i>
                                        <h5>Email</h5>
                                        <h6><a href="mailto:hello@mycarmelrealty.com">hello@mycarmelrealty.com</a></h6>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="contact-item">
                                        <i className="fas fa-map-marker-alt"></i>
                                        <h5>Address</h5>
                                        <h6>Carmel, Indiana</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="contact-item">
                                        <i className="fas fa-phone-alt"></i>
                                        <h5>Phone</h5>
                                        <h6><a href="tel:6174158031">617-415-8031</a></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="row mt-5">
                                <div className="col-lg-12 d-flex justify-content-center mt-5">
                                <a href="https://calendly.com/sheldonbarnes/showing" target="_blank" rel="noopener noreferrer" className="btn-contact">
                    Schedule a Meeting
                </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact