import React, { useState, useEffect, useRef } from 'react';
import AWS from 'aws-sdk';
import { useParams } from 'react-router-dom';
import FlatItem from "./FlatItem";

const Feature = () => {
  const { feature, city } = useParams();
  const [propertyImages, setPropertyImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const lastPropertyRef = useRef(null);
  const pageSize = 100000;

  const title = {
    text: `Find ${feature} photos with Carmel Realty LLC`,
    description: `Properties with ${feature} photos`
  };

  document.title = title.text;
  document.description = title.description;

  const toProperCase = (str) => {
    return str.toLowerCase().replace(/(?:^|\s)\w/g, match => match.toUpperCase());
  };

  const fetchData = async () => {
    if (loading) return; // Prevent multiple fetch calls if already loading

    setLoading(true);
    console.log("Fetching data..." + JSON.stringify(lastEvaluatedKey));

    AWS.config.region = process.env.REACT_APP_REGION;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
    });

    const docClient = new AWS.DynamoDB.DocumentClient();

    const params = {
      TableName: process.env.REACT_APP_IMAGES_TABLE,
      FilterExpression: 'LabelName = :label',
      ExpressionAttributeValues: {
        ':label': feature
      },
      Limit: pageSize,
      ExclusiveStartKey: lastEvaluatedKey
    };

    console.log("Scan params:", params);

    try {
      const data = await docClient.scan(params).promise();
      console.log("Fetched data:", data);
      let allImages = [];

      console.log("There are " + data.Items.length)
      for (const item of data.Items) {
        const propertiesParams = {
          TableName: process.env.REACT_APP_PROPERTIES_TABLE,
          Key: {
            'ListingKey': item.ListingKey
          },
          ExpressionAttributeValues: {
            ':status': 'Active'
          }          
        };

        const propertyData = await docClient.get(propertiesParams).promise();
        item.BedroomsTotal = propertyData.Item?.BedroomsTotal || 0;
        Object.assign(item, propertyData.Item);

        if (city) {
          if (toProperCase(city) === item.City) {
            allImages.push(item);
          }
        } else {
          allImages.push(item);
        }
      }

      setPropertyImages(prevImages => [...prevImages, ...allImages]);
      setLastEvaluatedKey(data.LastEvaluatedKey);
      console.log("LastEvaluatedKey:", data.LastEvaluatedKey);

    } catch (err) {
      console.error('Unable to scan the table. Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleIntersection = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && !loading && lastEvaluatedKey) {
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection);
    if (lastPropertyRef.current) {
      observer.observe(lastPropertyRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [lastEvaluatedKey, lastPropertyRef.current]);

  return (
    <React.Fragment>
      <section className="section-all-re">
        <div className="container">
          {loading && (
            <p>Searching for {feature} photos {city ? `in ${city}` : ''} (Please wait)...</p>
          )}
          {!loading && (
            <h1>Curated {feature} photos {city ? `in ${city}` : ''}</h1>
          )}
          {!loading && (
            <p>We understand that finding the perfect home is crucial to making a house feel like home. That's why we're excited to present a curated collection of photos showcasing {feature} in our property searches. Dive in and explore these stunning photos that could be part of your future dream home.</p>
          )}
          <div className="row row-cols-1 row-cols-md-1 row-cols-lg-4">
            {propertyImages.map((image, index) => (
              <div key={index} className="col image-container">
                <a href={`/home/${image.StreetNumber}-${image.StreetName}-${image.StreetSuffix}-${image.City}-${image.StateOrProvince}-${image.PostalCode}/${image.ListingKey}?image=${image.S3ImageName}`}>
                  <img 
                    src={`https://mls-property-media-prod-v1.s3.us-east-2.amazonaws.com/thumbnails/${image.S3ImageName}`} 
                    alt={`Image ${index}`} 
                    className="img-fluid"
                    style={{ width: '100%', height: 'auto' }}
                  /><br/>
                  <i className="overlay-dom1">{image.DaysOnMarket} {image.DaysOnMarket === 1 ? 'day' : 'days'} on market</i>
                  <i className="overlay-dom2">${image.ListPrice.toLocaleString()}</i>
                  <span className="item-title">{image.MlsStatus} | {image.BedroomsTotal} bds |  {image.StreetNumber} {image.StreetName} {image.StreetSuffix} {image.City}, {image.StateOrProvince}. {image.PostalCode}</span>
                </a>
              </div>
            ))}
          </div>
          <div ref={lastPropertyRef}></div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Feature;
