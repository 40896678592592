import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { useParams } from 'react-router-dom';

const SuperLike = () => {

    
    const { text } = useParams();

    var [propertyImages, setPropertyImages] = useState([]);
    var [currentImage, setCurrentImage] = useState("");
    var [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const handleLikeClick = () => {
        console.log("Iam clicking");

        setCurrentImageIndex(currentImageIndex => currentImageIndex + 1);
        setCurrentImage(propertyImages[currentImageIndex + 1]);
    
        console.log(currentImageIndex);
    };

    useEffect(() => {

        

        console.log("The search text is " + text);
        setSearchValue(text);

        if (propertyImages.length > 0) {
            setCurrentImage(propertyImages[currentImageIndex]);
        }


    }, [currentImageIndex, propertyImages]);

    useEffect(() => {
        //setSearchValue(new URLSearchParams(window.location.search).get('text') || "");
        handleSearch();
    }, []);

    const handleSearch = async () => {
        setLoading(true);
        const inputSearch = searchValue;

        AWS.config.region = process.env.REACT_APP_REGION;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
        });

        const docClient = new AWS.DynamoDB.DocumentClient();

        const params = {
            TableName: process.env.REACT_APP_IMAGES_TABLE,
            FilterExpression: 'LabelName = :label',
            ExpressionAttributeValues: {
                ':label': inputSearch
            },
        };

        console.log(params);

        try {
            const data = await docClient.scan(params).promise();

            for (const item of data.Items) {
                const propertiesParams = {
                    TableName: process.env.REACT_APP_PROPERTIES_TABLE,
                    Key: { 'ListingKey': item.ListingKey },
                };

                const propertyData = await docClient.get(propertiesParams).promise();

                item.BedroomsTotal = propertyData.Item.BedroomsTotal || 0;
                item.StreetNumber = propertyData.Item.StreetNumber;
                item.StreetName = propertyData.Item.StreetName;
                item.StreetSuffix = propertyData.Item.StreetSuffix;
                item.City = propertyData.Item.City;
                item.StateOrProvince = propertyData.Item.StateOrProvince;
                item.PostalCode = propertyData.Item.PostalCode;
            }
            console.log(data);
            data.Items.sort((a, b) => b.BedroomsTotal - a.BedroomsTotal);
            setPropertyImages(data.Items);
            currentImageIndex=0;

            setLoading(false);
        } catch (err) {
            console.error('Unable to scan the table. Error:', err);
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <section className="section-all-re">
                <div className="container">
                    {loading && <p>Searching Ackee for {searchValue} (Please wait)...</p>}
                    <div className="row">
                        <div className="col image-container container">
                            <img
                                src={`https://mls-property-media-prod-v1.s3.us-east-2.amazonaws.com/thumbnails/${currentImage.S3ImageName}`}
                                alt={`Image`}
                                className="image"
                                style={{ width: '100%', height: 'auto' }}
                            />
                            <br />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <i className="far fa-thumbs-down fa-3x" onClick={handleLikeClick}></i>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            <i className="far fa-heart fa-3x" onClick={handleLikeClick}></i>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default SuperLike;
