import {Link} from "react-router-dom"

const CityItem = ({title,link, image, description, population}) => {
    return (
        <div className="col-lg-4">
            <div className="blog-item">
                <div className="blog-img">
                <a href={`/cities/${title}`}>  <img src={`/img/${image}`} alt="product" className="w-100" style={{ maxHeight: '250px' }} /> </a>
                </div>
                <div className="blog-content">
                    <h2 className="blog-title"><Link to={`/cities/${title}`}>{title}</Link></h2>
                    <div className="blog-info">
                        <div className="blog-info-item"><i className="far fa-calendar-alt "></i><span>{population}</span></div>
                    </div>
                    <div className="blog-text">
                        {description}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CityItem