import Title from "./Title"
import FlatItem from "./FlatItem"
import React, { useState, useEffect, useRef } from 'react';
import AWS from 'aws-sdk';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';

const FlatList = () => {
  const [properties, setProperties] = useState([]);
  const pageSize = 50; // Adjust this number based on how many records you want to fetch per page
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null); // State to store last evaluated key
  const lastPropertyRef = useRef(null); // Ref for the last property element

  const title = {
    text: "Lorem Ipsum",
    description: "Lorem ipsum dolor sit ame"
  };

  const fetchData = async () => {
    const params1 = {
      TableName: process.env.REACT_APP_PROPERTIES_TABLE,
      ProjectionExpression: 'ListingKey, ListPrice, MlsStatus, BedroomsTotal, BathroomsTotalInteger, StreetNumber, StreetName, StreetSuffix, City, StateOrProvince, PostalCode, SubdivisionName, MBR_LastStatus, Media',
      Limit: pageSize,
      ExclusiveStartKey: lastEvaluatedKey
    };

    const params = {
      TableName: process.env.REACT_APP_PROPERTIES_TABLE,
      ProjectionExpression: 'ListingKey, ListPrice, ListOfficeName, DaysOnMarket, BuildingAreaTotal, MlsStatus, BedroomsTotal, BathroomsTotalInteger, StreetNumber, StreetName, StreetSuffix, City, StateOrProvince, PostalCode, SubdivisionName, MBR_LastStatus, Media',
      Limit: pageSize,
      ExclusiveStartKey: lastEvaluatedKey,
      FilterExpression: 'MlsStatus = :status',
      ExpressionAttributeValues: {
          ':status': 'Active'
      }
    };

    console.log("The prams" + JSON.stringify(params));


    AWS.config.region ="us-east-2";
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
    });
    const docClient = new DocumentClient(); 

    docClient.scan(params, async function(err, data) {
      if (err) {
        console.error('Unable to scan the table. Error JSON:', JSON.stringify(err, null, 2));
      } else {
        console.log('Scan succeeded.');
        console.log(data.Items.length + " properties returned");

        // Set properties with image data
        data.Items.sort((a, b) => b.BedroomsTotal - a.BedroomsTotal);
        setProperties(prevProperties => [...prevProperties, ...data.Items]);

        // Update the last evaluated key
        setLastEvaluatedKey(data.LastEvaluatedKey);
      }
    });
  };    

  useEffect(() => {
    fetchData();
  }, []);

  const handleViewMoreClick = () => {
    fetchData();
  };

  // Function to handle intersection observer
  const handleIntersection = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      fetchData(); // Load more data when the last property comes into view
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection);
    if (lastPropertyRef.current) {
      observer.observe(lastPropertyRef.current);
    }
    return () => {
      observer.disconnect(); // Clean up the observer
    };
  }, [lastPropertyRef]);

  return (
    <section className="section-all-re">
      <div className="container">
        <div className="row">
          {properties.map((property, index) => (
            <FlatItem key={index} slug={property}/>
          ))}
          {lastEvaluatedKey && (
            <button onClick={handleViewMoreClick}>View More</button>
          )}
                  <div className="bottom-bar-content">
      <center><button>Request a Tour</button></center>
      </div>
          <div ref={lastPropertyRef}></div> {/* Ref for the last property */}
        </div>


      </div>
    </section>
  );
};

export default FlatList;
  