import React, { useState, useEffect } from 'react';

import AWS from 'aws-sdk';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import FlatItem from "./FlatItem"
import FlatItemO from "./FlatItemO"

var searchText = "";

const OpenHouses = () => {
    
    const { feature } = useParams();
    const [liked, setLiked] = useState();

    const [properties, setProperties] = useState([]);
    const [openhouses, setOpenHouses] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [loading, setLoading] = useState(false); // State variable for loading indicator
    const title = {
        text: "Open houses in " + feature, 
        description: "Open houses in " + feature
    }
    document.title = title.text;
    document.description = title.description;

    function toProperCase(str) {
        return str.toLowerCase().replace(/(?:^|\s)\w/g, function(match) {
            return match.toUpperCase();
        });
    }
    const handleLikeClick = () => {
        // Retrieve existing liked properties array from local storage
        /*console.log(slug.liked);
        slug.liked = !slug.liked;

        setLiked(!liked);
        const likedProperties = JSON.parse(localStorage.getItem('likedProperties')) || [];
        if (liked) {
            // Remove property from likedProperties if it was previously liked
            const updatedProperties = likedProperties.filter(property => property.ListingKey !== slug.ListingKey);
            localStorage.setItem('likedProperties', JSON.stringify(updatedProperties));
        } else {
            // Add property to likedProperties if it was not previously liked
            likedProperties.push(slug);
            localStorage.setItem('likedProperties', JSON.stringify(likedProperties));
        }*/

    };

    useEffect(() => {
        
        handleSearch()
    }, []);

 
    function propertyByKey(ListingKey) {
        console.log("Looking for " + ListingKey);

        var prop =  properties.find(item => item.ListingKey === ListingKey);
        console.log(properties);
        
    }
    const handleSearch = async () => {
        //console.log("Searching");
        setLoading(true); // Set loading to true before fetching data

        // Call the useEffect hook to re-fetch the data with the new search value
        //setSearchValue(document.getElementById("searchInput").value);

        // var inputSearch = toProperCase(document.getElementById("searchInput").value);

        const paramCities = new URLSearchParams(window.location.search);
        const cities = paramCities.get('cities');

        var inputSearch = feature;
        if (!inputSearch) {
            inputSearch = searchText;
        }

        //console.log(searchText);

        AWS.config.region = process.env.REACT_APP_REGION;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
        });

        const docClient = new AWS.DynamoDB.DocumentClient();

        const params = {
            TableName: process.env.REACT_APP_OPENHOUSES_TABLE,
            Limit: 10
        };

        //console.log(params);

        try {
            //const data = await docClient.scan(params).promise();
            var records = 0;
            let data;
            let allProperties = [];
            do {
                data = await docClient.scan(params).promise();
                

                for (const item of data.Items) {
                    //console.log(item);
            
                    //const selectedCities = cities ? cities.split(',') : [];


                    var propertiesParams = {
                        TableName: process.env.REACT_APP_PROPERTIES_TABLE,
                        Key: {
                            'ListingKey': item.ListingKey
                        },
                    };
 
                    //console.log(propertiesParams);
            
                    const propertyData = await docClient.get(propertiesParams).promise();    
                    if (propertyData.Item && propertyData.Item.City==feature) {
                        console.log(propertyData.Item.ListingKey);
                        item.property = {}

                        const date = new Date(item.ListingDate);

                        // Use Intl.DateTimeFormat to format the date
                        const options = { year: 'numeric', month: 'long', day: 'numeric' };
                       // item.ListingDate =new Intl.DateTimeFormat('en-US', options).format(date);
                        Object.assign(item.property, propertyData.Item);
                        allProperties.push(item);
                    }

            
                    records++; // Assuming 'records' is defined elsewhere in your code

                }

            
                // Update ExclusiveStartKey for next scan if there are more pages
                params.ExclusiveStartKey = data.LastEvaluatedKey;
            
            } while (typeof data.LastEvaluatedKey !== 'undefined');

     
            setOpenHouses(allProperties);

            console.log(data.Items.length);
            console.log(allProperties.length);

            // data.Items.sort((a, b) => b.BedroomsTotal - a.BedroomsTotal);
            //setPropertyImages(data.Items);
            allProperties.sort((a,b) => a.OpenHouseDate - b.OpenHouseDate);

            setProperties(allProperties); 

            console.log('Scan succeeded. Data:', data);

        } catch (err) {
            console.error('Unable to scan the table. Error:', err);
        }finally {
            setLoading(false); // Set loading back to false after fetching data
        }
    };

    return (
        <React.Fragment>
            
            <section className="section-all-re">
                <div className="container">
                {loading && <p>Searching Ackee  for {feature} (Please wait)...</p>}
                <p>Are you excited about exploring open houses in {feature}? You're in the right place! Whether you're on the hunt for your dream home or just love seeing the latest listings, we've got you covered. Below, you'll find a curated list of upcoming open houses in {feature}, making it easier than ever to plan your visits and stay ahead in the market. Dive in, discover stunning properties, and envision your future home with us!</p>
                    <div className="row row-cols-1 row-cols-md-1 row-cols-lg-4">
                        {properties.map((openhouse, index) => (
                            <div key={index} className="col image-container1">   
                                    <FlatItemO key={index} openhouse={openhouse}/>      
                            </div>
                        ))}
                    </div>
                </div>
            </section>

        </React.Fragment>
    )
}

export default OpenHouses;
