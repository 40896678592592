import FlatList from "./FlatList"
import Banner from "./Banner"
import React, { useState, useEffect } from 'react';
import TeamList from "./TeamList"
import References from "./References"
import Subscribe from "./Subscribe"
import BestFlatList from "./BestFlatList"
import AWS from 'aws-sdk';

const Home=()=>{
    return (
        <React.Fragment>   
            <Banner/>         
            <FlatList/>
            <div className="bottom-bar">
                <div className="bottom-bar-content">
                    <img class="image-bottom-photo" src="/img/sheldon.jpeg" alt="Profile Picture"/>
                    <div class="contact-info">
                        <h3>Sheldon Barnes</h3>
                        <p>Experienced Realtor from Carmel Realty LLC </p>
                        <small>Feel free to text or call </small>
                        <div class="contact-buttons">
                            <a href="sms:+16174158031?body=Hello%20Sheldon,%20I'm looking for a real estate agent."><button>Text</button></a>
                            <a href="tel:+16174158031"><button>Call</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Home;