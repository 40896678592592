import Title from "./Title"
import FlatItem from "./FlatItem"
import React, { useState, useEffect, useRef } from 'react';
import AWS from 'aws-sdk';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import { useParams } from 'react-router-dom';
import SubdivisionsList from "./SubdivisionsList"
import { Link } from "react-router-dom";

var lastStuff = "Sheldon";
var firstRun = true;
var stopFetching = false;

const Cities = () => {
  const { city, bedrooms, bathrooms } = useParams();
  const [properties, setProperties] = useState([]);
  const pageSize = 10;
  var [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  var lastPropertyRef = useRef(null); // Ref for the last property element

  const fetchData = async () => {
    console.log("fetchData is being called");
    console.log("The lastStuff is " + lastEvaluatedKey)

    let latestLastEvaluatedKey = lastEvaluatedKey; // Capture the latest value

    const params = {
      TableName: process.env.REACT_APP_PROPERTIES_TABLE,
      IndexName: 'City-BedroomsTotal-index',
      ProjectionExpression: 'ListingKey, ListPrice,ListOfficeName,DaysOnMarket, BuildingAreaTotal, MlsStatus, BedroomsTotal, BathroomsTotalInteger, StreetNumber, StreetName, StreetSuffix, City, StateOrProvince, PostalCode, SubdivisionName, MBR_LastStatus, Media',
      Limit: pageSize,
      ExclusiveStartKey: latestLastEvaluatedKey,
      KeyConditionExpression: 'City = :city',
      ExpressionAttributeValues: {
        ':city': city,
      },
      ScanIndexForward: false,
    };

    if (bedrooms) {
      params.KeyConditionExpression += ' AND BedroomsTotal = :bedrooms';
      params.ExpressionAttributeValues[':bedrooms'] = parseInt(bedrooms);
    }

    if (bathrooms) {
      params.FilterExpression = 'BathroomsTotalInteger = :bathrooms';
      params.ExpressionAttributeValues[':bathrooms'] = parseInt(bathrooms);
    }

    console.log("The parameter is " + JSON.stringify(params));
    AWS.config.region ="us-east-2";
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
    });
    const docClient = new DocumentClient(); 

    docClient.query(params, async function(err, data) {
      if (err) {
        console.error('Unable to scan the table. Error JSON:', JSON.stringify(err, null, 2));
      } else {
        console.log('Scan succeeded.');
        console.log(data.Items.length + " properties returned");

        firstRun = false;
        lastStuff = data.LastEvaluatedKey;
        console.log("The last stuff again before is " + JSON.stringify(lastStuff) + " first run is " + firstRun);

        const newProperties = data.Items;

        console.log("Data nextToken" + data.nextToken);
        if (data.LastEvaluatedKey) {
          setLastEvaluatedKey(data.LastEvaluatedKey); // Update the state with the latest value
          lastEvaluatedKey = data.LastEvaluatedKey;
          setProperties(prevProperties => [...prevProperties, ...newProperties]);
        } else {
          stopFetching = true;
          setLastEvaluatedKey(null); // Update the state with the latest value
          setProperties(prevProperties => [...prevProperties, ...newProperties]);
        }
        return;

      }
    });
  };    

  useEffect(() => {
    fetchData(); // Call fetchData once when the component mounts
  }, []); // Empty dependency array ensures it runs only once on mount

  const handleIntersection = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && !stopFetching) {
      fetchData(); // Load more data when the last property comes into view
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection);
    if (lastPropertyRef.current) {
      observer.observe(lastPropertyRef.current);
    }
    return () => {
      observer.disconnect(); // Clean up the observer
    };
  }, [lastPropertyRef, stopFetching]);

  return (
    
      <div className="container">
      <div className="row">
        <div className="col-lg-12">
        <div className="fd-top flat-detail-content">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/cities`}>Cities</Link></li>
                                <li className="breadcrumb-item">{city}</li>
                                </ol>
                            </nav>    
                            </div>
        {bedrooms && bathrooms ? (
            <p>See {bedrooms} bedroom and {bathrooms} bathroom properties in {city}</p>
          ) : bedrooms ? (
            <p>See {bedrooms} bedroom properties in {city}</p>
          ) : bathrooms ? (
            <p>See {bathrooms} bathroom properties in {city}</p>
          ) : (
            <p>See properties in {city}</p>
          )}          
        </div>
      </div>
        <div className="row">
          {properties.map((property, index) => (
            <FlatItem key={index} slug={property}/>
          ))}
          <div ref={lastPropertyRef}></div> {/* Ref for the last property */}
        </div>
      </div>

  );
};

export default Cities;
