
import Title from "./Title"
import FlatItem from "./FlatItem"
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AWS from 'aws-sdk';
import { Link } from "react-router-dom";

const SubdivisionList = ({slug}) => {
    const [properties, setProperties] = useState([]);

    console.log("The subdivision is " + slug);
    const title = {
        text: "Home in " + slug.subdivision + " of Carmel",
        description: "Lorem ipsum dolor sit ame"
    }
    document.title = title.text;
    useEffect(() => {
        // Configure AWS SDK
    
        AWS.config.region = process.env.REACT_APP_REGION;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
        });
    
        // Create S3 instance
        const s3 = new AWS.S3();
    
        // Create DynamoDB instance
        const docClient = new AWS.DynamoDB.DocumentClient();
    
        // Define parameters for DynamoDB scan
        /*const params1 = {
          TableName: process.env.REACT_APP_PROPERTIES_TABLE,
          ProjectionExpression: 'ListingKey, ListPrice, BedroomsTotal, StreetNumber, StreetName, StreetSuffix, City, StateOrProvince, PostalCode, SubdivisionName, MBR_LastStatus, Media', // Include Media array
          FilterExpression: 'SubdivisionName = :subdivisionName', // Filter by city
          ExpressionAttributeValues: {
            ':subdivisionName': subdivision
          }          
        };*/

        const params = {
          TableName: 'mls-grid-properties-prod',
          IndexName: 'SubdivisionName-index', // Specify the index name you created
          KeyConditionExpression: 'SubdivisionName = :subdivision',
          FilterExpression: 'City = :city',
          ExpressionAttributeValues: {
            ':subdivision': slug.subdivision, // Assuming subdivisionValue is the subdivision you want to query for
            ':city': slug.city // Assuming cityValue is the city you want to filter by
          }
        };
    
        // Fetch data from DynamoDB
        docClient.query(params, async function(err, data) {
          if (err) {
            console.error('Unable to scan the table. Error JSON:', JSON.stringify(err, null, 2));
          } else {
            console.log('Scan succeeded.');
    
            // Iterate over each property to extract image URL and pull image from S3
            for (const property of data.Items) {
            }
    
            // Set properties with image data
            // data.Items.sort((a, b) => a.SubdivisionName - b.SubdivisionName);
            setProperties(data.Items);
          }
        });
      }, []);
    
    return (
        <section className="section-all-re">
            <div className="container">
                <div className="row">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={`/cities`}>Cities</Link></li>
                  <li className="breadcrumb-item"><Link to={`/cities/${slug.city}`}>{slug.city}</Link></li>
                  <li className="breadcrumb-item"><Link to={`/subdivisions/${slug.city}`}>Subdivisions</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{slug.subdivision}</li>
                  </ol>
                </nav>                  
                <h1>{slug.subdivision} Homes for Sale In {slug.city}</h1>
                  <p>
                  Explore all available homes for sale in the {slug.subdivision} subdivision of {slug.city}, Indiana, with daily updates from the MLS Grid.</p>
                </div>
                <div className="row"> 
                {properties.map((property, index) => (
                    <FlatItem key={index} slug={property}/>                    
                ))}
                </div>
            </div>
        </section>
    )

}

export default SubdivisionList;