import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AWS from 'aws-sdk';
import SubdivisionCard from './SubdivisionCard';
import { Link } from "react-router-dom";

const SubdivisionsList = ({ slug }) => {
  const [subdivisions, setSubdivisions] = useState([]);
  const city = slug;

  useEffect(() => {
    AWS.config.region = process.env.REACT_APP_REGION;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
    });

    const docClient = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: 'mls-grid-properties-prod',
      ExpressionAttributeValues: {
        ':city': city
      },
      FilterExpression: 'City = :city',
      ProjectionExpression: 'SubdivisionName',
    };

    // Function to scan all pages
    const scanAllSubdivisions = async () => {
      let allSubdivisions = [];
      let lastEvaluatedKey = undefined;

      do {
        const scanParams = lastEvaluatedKey ? { ...params, ExclusiveStartKey: lastEvaluatedKey } : params;
        const data = await docClient.scan(scanParams).promise();

        if (data.Items) {
          data.Items.forEach(item => {
            if (!allSubdivisions.includes(item.SubdivisionName) && item.SubdivisionName)
              allSubdivisions.push(item.SubdivisionName);
          });
        }

        lastEvaluatedKey = data.LastEvaluatedKey;
      } while (lastEvaluatedKey);

      return allSubdivisions;
    };

    // Fetch subdivisions and set state
    scanAllSubdivisions()
      .then(subdivisions => {
        setSubdivisions(subdivisions.sort()); // Sort subdivisions alphabetically
      })
      .catch(err => {
        console.error('Error scanning subdivisions:', err);
      });
  }, [city]);

  return (
    <section className="section-all-re">
      <div className="container">
        <div className="row">
        <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={`/cities`}>Cities</Link></li>
                  <li className="breadcrumb-item"><Link to={`/cities/${city}`}>{city}</Link></li>
                  <li className="breadcrumb-item">Subdivisions</li>
                  </ol>
                </nav>                   
          <h1>{city} subdivisions (neighborhoods)</h1>
          <p>Already decided on a neighborhood in {city}, Indiana? Browse the list below to find your ideal home for sale in Carmel. All listings are refreshed daily from the MLS Grid.</p>
          <p>For additional details on any of these {city} properties, simply click the "Request More Information" button while viewing the property's details. We can offer disclosures, past sales history, dates, prices of recently sold homes, and more.</p>
        </div>
        <div className="row">
          {subdivisions.map((subdivision, index) => (
            <SubdivisionCard key={index} subdivision={subdivision} city={city} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SubdivisionsList;
