import { Link } from "react-router-dom";
// src/PropertyList.js
import React, { useState, useEffect } from 'react';
const FlatItemO = ({openhouse}) => {


    var slug = openhouse.property;
    
    const [liked, setLiked] = useState(slug.liked);

    const handleLikeClick = () => {
        // Retrieve existing liked properties array from local storage
        console.log(slug.liked);
        slug.liked = !slug.liked;

        setLiked(!liked);
        const likedProperties = JSON.parse(localStorage.getItem('likedProperties')) || [];
        if (liked) {
            // Remove property from likedProperties if it was previously liked
            const updatedProperties = likedProperties.filter(property => property.ListingKey !== slug.ListingKey);
            localStorage.setItem('likedProperties', JSON.stringify(updatedProperties));
        } else {
            // Add property to likedProperties if it was not previously liked
            likedProperties.push(slug);
            localStorage.setItem('likedProperties', JSON.stringify(likedProperties));
        }

    };

    function formatDateTime(dateTimeString) {
        // Create a new Date object from the input string
        const date = new Date(dateTimeString);
      
        // Define options for formatting the date and time
        const options = {
          year: 'numeric',
          month: 'long', // 'long' for full month name, 'short' for abbreviated month name
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true // Use 12-hour format
        };
      
        // Format the date and time using Intl.DateTimeFormat
        return new Intl.DateTimeFormat('en-US', options).format(date);
      }
      
      useEffect(() => {
        // Retrieve liked properties from local storage
        // console.log(imageName);
        slug.Media.sort((a, b) => a.Order - b.Order);
        const imageName = slug.Media[0].MediaURL.split('/').pop();

        slug.Media[0].MediaURL = "https://mls-property-media-prod-v1.s3.us-east-2.amazonaws.com/" + imageName;
        // console.log(slug.Media[0].MediaURL);        
        const likedProperties = JSON.parse(localStorage.getItem('likedProperties')) || [];
        // Check if the current property is in the liked properties
        const isLiked = likedProperties.some(property => property.ListingKey === slug.ListingKey);
        // Update liked state based on the result
        setLiked(isLiked);
    }, [slug.ListingKey]); // Add slug.ListingKey to dependency array


    return (
    
        <div className="col-lg-12">
 
            <div className="cardo">

                <img className="card-img-top" src={openhouse.property.Media[0].MediaURL} alt="Subdivision" />
                <div className={`open-house-state }`}>Open House</div>

                <div className="card-body">
                    <h5 className="card-title">{openhouse.property.StreetNumber} {openhouse.property.StreetName} {openhouse.property.StreetSuffix}, {openhouse.property.City} {openhouse.property.StateOrProvince} {openhouse.property.PostalCode}</h5>
                    <p><font>{openhouse.OpenHouseType}</font></p>
                    <p><font color="#d1410c">{formatDateTime(openhouse.OpenHouseStartTime)}</font></p>
                    <p><font>{openhouse.OpenHouseRemarks}</font></p>
                    <button className="btn btn-detail">Request Tour</button>
                    {/* You can add additional information about the subdivision here */}
                </div>
            </div>
        </div>
    )
}

export default FlatItemO