import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import { useParams } from 'react-router-dom';
import AWS from 'aws-sdk';
import { Link } from "react-router-dom";
// import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Helmet } from 'react-helmet';
const FlatDetail = () => {

    //var calendlyURL;
    const likedProperties = JSON.parse(localStorage.getItem('likedProperties')) || [];
    let galleryIndex = 0;

    const [showFullRemarks, setShowFullRemarks] = useState(false);

    const toggleRemarks = () => {
        setShowFullRemarks(!showFullRemarks);
      };

    const mapStyles = {
        height: '400px',
        width: '100%'
    };

    const defaultCenter = {
        lat: 37.7749,
        lng: -122.4194
    };

    const { address, listingKey } = useParams();
    const [property, setProperty] = useState(null);
    const [calendlyURL, setCalendlyURL] = useState(null);
    const [imageParam, setImageParam] = useState(null);  // Declare imageParam state

    useEffect(() => {
        const paramSearch = new URLSearchParams(window.location.search);
        const imageParam = paramSearch.get('image');

        const [streetNumber, streetName, streetSuffix, city, stateOrProvince, postalCode] = address.split('-');

        document.title = `${streetNumber} ${streetName} ${streetSuffix} ${city}`;
// Set the document title
document.title = `${streetNumber} ${streetName} ${streetSuffix} ${city}`;



        AWS.config.region = process.env.REACT_APP_REGION;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
        });

        const docClient = new AWS.DynamoDB.DocumentClient();

        const params = {
            TableName: process.env.REACT_APP_PROPERTIES_TABLE,
            KeyConditionExpression: 'ListingKey = :listingKey',
            ExpressionAttributeValues: {
                ':listingKey': listingKey
            }
        };

        docClient.query(params, function (err, data) {
            if (err) {
                console.error('Unable to query the table. Error JSON:', JSON.stringify(err, null, 2));
            } else {
                if (data.Items.length > 0) {
                    console.log(data.Items[0].ListingKey)


                    data.Items[0].Media.sort((a, b) => a.Order - b.Order);
                    console.log(data.Items[0].Media)
                    const index = data.Items[0].Media.findIndex(item => item.MediaURL.includes(imageParam));
                    galleryIndex = index === -1 ? 0 : index;
                    data.Items[0].galleryIndex = galleryIndex;
                    setProperty(data.Items[0]);

                   
                    var property = data.Items[0];
                    var propertyAddress = `${property.StreetNumber} ${property.StreetName} ${property.StreetSuffix} ${property.City} ${property.StateOrProvince} ${property.PostalCode}`;
                    console.log(propertyAddress);

                    setCalendlyURL(`https://calendly.com/sheldonbarnes/showing?a2=${encodeURIComponent(propertyAddress)}`);                    
                    console.log(calendlyURL);

                } else {
                    console.log('No property found for the specified address.');
                }
            }
        });
    }, [address, listingKey]);

    const getMetaContent = (content) => {
        return content ? content.toString() : '';
    }

    return (    
        <div className="flat-detail">                

            <Helmet>
                {property && [
                    <link rel="canonical" href={`https://www.ackee.ai/home/${property.StreetNumber}-${property.StreetName}-${property.StreetSuffix}-${property.City}-${property.StateOrProvince}-${property.PostalCode}/${property.ListingKey}`} />,
                    <meta key="og-title" property="og:title" content={getMetaContent(`${property.StreetNumber} ${property.StreetName} ${property.StreetSuffix}, ${property.City}`)} />,
                    <meta key="og-description" property="og:description" content={getMetaContent(property.PublicRemarks)} />,
                    <meta key="og-image" property="og:image" content={getMetaContent(imageParam 
                        ? `https://mls-property-media-prod-v1.s3.us-east-2.amazonaws.com/thumbnails/${imageParam}` 
                        : `https://mls-property-media-prod-v1.s3.us-east-2.amazonaws.com/thumbnails/${property.Media[0].MediaURL.split('/').pop()}`)} />,
                    <meta key="og-url" property="og:url" content={getMetaContent(window.location.href)} />,
                    <meta key="og-type" property="og:type" content="website" />,
                    <meta key="twitter-card" name="twitter:card" content="summary_large_image" />,
                    <meta key="twitter-title" name="twitter:title" content={getMetaContent(`${property.StreetNumber} ${property.StreetName} ${property.StreetSuffix}, ${property.City}`)} />,
                    <meta key="twitter-description" name="twitter:description" content={getMetaContent(property.PublicRemarks)} />,
                    <meta key="twitter-image" name="twitter:image" content={getMetaContent(imageParam 
                        ? `https://mls-property-media-prod-v1.s3.us-east-2.amazonaws.com/thumbnails/${imageParam}` 
                        : `https://mls-property-media-prod-v1.s3.us-east-2.amazonaws.com/thumbnails/${property.Media[0].MediaURL.split('/').pop()}`)} />
                ]}
            </Helmet>
            {property ? (
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="fd-top flat-detail-content">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/subdivision/${property.City}/${property.SubdivisionName}/`}>{property.City}</Link></li>
                                <li className="breadcrumb-item"><Link to={`/subdivisions/${property.City}`}>Subdivisions</Link></li>
                                <li className="breadcrumb-item"><Link to={`/subdivision/${property.City}/${property.SubdivisionName}/`}>{property.SubdivisionName}</Link></li>
                                <li className="breadcrumb-item">{property.StreetNumber} {property.StreetName} {property.StreetSuffix}</li>
                                </ol>
                            </nav>    
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ImageGallery
                                startIndex={property.galleryIndex}
                                flickThreshold={0.50}
                                slideDuration={0}
                                items={property.Media.map(media => ({
                                    originalAlt: media.LongDescription,
                                    loading: "lazy",
                                    original: `https://mls-property-media-prod-v1.s3.us-east-2.amazonaws.com/${media.MediaURL.split('/').pop()}`,
                                    thumbnail: `https://mls-property-media-prod-v1.s3.us-east-2.amazonaws.com/thumbnails/${media.MediaURL.split('/').pop()}`
                                }))}
                                showNav={true}
                                showFullscreenButton={false}
                                showPlayButton={true}
                            />
<div className="bottom-bar">
    <div className="bottom-bar-content">
        <img class="image-bottom-photo" src="/img/sheldon.jpeg" alt="Profile Picture"/>
        <div class="contact-info">
            <h3>Connect with Realtor Sheldon Barnes</h3>
            <p>Want to tour {property.StreetNumber} {property.StreetName} {property.StreetSuffix}?</p>
            <p>Feel free to text or call for tours and more info about this property</p>
            <div class="contact-buttons">
            <a href={`sms:+16174158031?body=Hello Sheldon, I am interested in ${property.StreetNumber} ${property.StreetName} ${property.StreetSuffix}`}>
            <button>Text</button>
            </a>

                <a href="tel:+16174158031"><button>Call</button></a>
            </div>
        </div>
    </div>
</div>



    
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="fd-item">
                <h4>${property.ListPrice.toLocaleString()}</h4>
                        <span className="item-title"> 
                        <a href={`/cities/${property.City}/${property.BedroomsTotal}`}>{property.BedroomsTotal} bds</a> | &nbsp;
                        <a href={`/cities/${property.City}/${property.BedroomsTotal}/${property.BathroomsTotalInteger}`}>{property.BathroomsTotalInteger} bths</a> | {property.BuildingAreaTotal} sqft - {property.MlsStatus}
                        <br/>  {property.StreetNumber} {property.StreetName} {property.StreetSuffix}, <a href={`/cities/${property.City}`}>{property.City}</a>, {property.StateOrProvince}. {property.PostalCode}
                        </span><br></br><br></br>
                            <i className="fas fa-object-group"></i>&nbsp; 
                            <span className="item-title">
                                Subdivision&nbsp; 
                                <Link 
                                    to={`/subdivision/${property.City}/${property.SubdivisionName}`} 
                                    className="item-title"
                                    title={`View homes in ${property.SubdivisionName} subdivision`}                                    
                                >
                                    {property.SubdivisionName} 
                                </Link>
                            </span> <br></br>
                            <span className="item-title">
                            <small>Listing provided by: {property.ListOfficeName}</small>
                            </span>
                        {/* 
                        <Link to={`/home/${slug.StreetNumber}-${slug.StreetName}-${slug.StreetSuffix}-${slug.City}-${slug.StateOrProvince}-${slug.PostalCode}/${slug.ListingKey}`} className="item-title">
                            <button className="btn btn-detail">View</button>
                        </Link>*/}
                    </div>                            
                        <div className="fd-item">
                                        <h4>Description</h4>
                                            {showFullRemarks ? (
                                                    <>
                                                    <p>{property.PublicRemarks}</p>
                                                    <a href="#" onClick={toggleRemarks}>Show less</a>
                                                    </>
                                                ) : (
                                                    <>
                                                    <p>{property.PublicRemarks.slice(0, 200)}...</p> {/* Display first 100 characters */}
                                                    <a href="#" onClick={toggleRemarks}>Read More</a>
                                                    </>
                                                )}
                                    </div>
                        </div>
                    </div>

                    <div className="row">
                    <div className="col-lg-12">

                    <div className="d-flex justify-content-between mb-3">
                    </div>
                </div>                        
                            <div className="fd-top flat-detail-content">
       
                
                            <div className="row">
                                <div className="col-lg-8">

                                    <div className="fd-item fd-property-detail">
                                        <h4>Property Details</h4>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <span>{property.PropertyType}</span>
                                            </div>
                                            <div className="col-lg-4">
                                                <span>{property.PropertySubType}</span>
                                            </div>
                                            <div className="col-lg-4">
                                                <span>Year Built: {property.YearBuilt}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <span>{property.LotSizeAcres} Acres lot</span>
                                            </div>
                                            <div className="col-lg-4">
                                                <span>{property.SubdivisionName}</span>
                                            </div>
                                            <div className="col-lg-4">
                                                <span>Bathrooms:</span>
                                                <span>{property.BathroomsTotalInteger}</span>
                                            </div>
                                        </div>
{/*                                         <div className="row">
                                            <div className="col-lg-4">
                                                <span>Kitchen:</span>
                                                <span>1</span>
                                            </div>
                                            <div className="col-lg-4">
                                                <span>All Rooms:</span>
                                                <span>5</span>
                                            </div>
                                            <div className="col-lg-4">
                                                <span>Kitchen:</span>
                                                <span>1</span>
                                            </div>
                                        </div>
 */}                                    </div>
                                    <div className="fd-item fd-features">
                                        <h4>Rooms</h4>
                                        <div className="row">
                                            {property.Rooms && property.Rooms.map((rooms, index) => (
                                                <div className="col-lg-4" key={index}>
                                                    <i className="fa fa-check"></i>
                                                    <span>{rooms.RoomType}:</span>
                                                    <span>{rooms.RoomDimensions}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-4">
                                    <div className="fd-sidebar-item">
                                        <h4>Popular Searches</h4>
                                        <ul className="category-ul">
                                            <li><a href={`/feature/Kitchen/${property.City}`}>Kitchen</a></li>
                                            <li><a href={`/feature/Living Room/${property.City}`}>Living Room</a></li>
                                            <li><a href={`/feature/Bathroom/${property.City}`}>Bathroom</a></li>
                                            <li><a href={`/feature/Walk-In Closet/${property.City}`}>Walk-In Closet</a></li>
                                        </ul>
                                    </div>
                                    <div className="fd-sidebar-item">
                                        <h4>Contact Us</h4>
                                        <ul className="category-ul">
                                            <li><a href="#"><i className="fas fa-phone-alt"></i>(617) 415-8031</a></li>
                                            <li><a href="#"><i className="fas fa-envelope"></i>hello@mycarmelrealty.com</a></li>
                                            <li><a href="#"><i className="fas fa-map-marker-alt"></i>Carmel, Indiana</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
}

export default FlatDetail;
