import { Link } from "react-router-dom";
// src/PropertyList.js
import React, { useState, useEffect } from 'react';
const FlatItem = ({slug}) => {

    const [liked, setLiked] = useState(slug.liked);

    const handleLikeClick = () => {
        // Retrieve existing liked properties array from local storage
        console.log(slug.liked);
        slug.liked = !slug.liked;

        setLiked(!liked);
        const likedProperties = JSON.parse(localStorage.getItem('likedProperties')) || [];
        if (liked) {
            // Remove property from likedProperties if it was previously liked
            const updatedProperties = likedProperties.filter(property => property.ListingKey !== slug.ListingKey);
            localStorage.setItem('likedProperties', JSON.stringify(updatedProperties));
        } else {
            // Add property to likedProperties if it was not previously liked
            likedProperties.push(slug);
            localStorage.setItem('likedProperties', JSON.stringify(likedProperties));
        }

    };

    
      useEffect(() => {
        // Retrieve liked properties from local storage
        // console.log(imageName);
        slug.Media.sort((a, b) => a.Order - b.Order);
        const imageName = slug.Media[0].MediaURL.split('/').pop();

        slug.Media[0].MediaURL = "https://mls-property-media-prod-v1.s3.us-east-2.amazonaws.com/" + imageName;
        // console.log(slug.Media[0].MediaURL);        
        const likedProperties = JSON.parse(localStorage.getItem('likedProperties')) || [];
        // Check if the current property is in the liked properties
        const isLiked = likedProperties.some(property => property.ListingKey === slug.ListingKey);
        // Update liked state based on the result
        setLiked(isLiked);
    }, [slug.ListingKey]); // Add slug.ListingKey to dependency array


    return (
    
        <div className="text-center col-lg-4 col-12 col-md-6 ">
            <div className="item">
                <div className="item-image">
                    { slug.Media && slug.Media.length > 0 && (
                        <div className="image-container">
                            <Link to={`/home/${slug.StreetNumber}-${slug.StreetName}-${slug.StreetSuffix}-${slug.City}-${slug.StateOrProvince}-${slug.PostalCode}/${slug.ListingKey}`} className="item-title">
                                <img className="img-fluid" src={"https://mls-property-media-prod-v1.s3.us-east-2.amazonaws.com/thumbnails/" + slug.Media[0].MediaURL.split('/').pop()} alt="flat" />
                            </Link>
                            <i className={liked ? "fas fa-heart overlay-heart" : "far fa-heart overlay-heart"} id="likeButton" onClick={handleLikeClick}></i>
  
                            {slug.MlsStatus === 'Active' && (
                            <i className="overlay-dom">
                                {slug.DaysOnMarket} {slug.DaysOnMarket === 1 ? 'day' : 'days'} on market
                            </i>
                            )}

                            {slug.MlsStatus === 'Sold' && (
                            <i className="overlay-dom">
                                Sold
                            </i>
                            )}

                            {slug.MlsStatus === 'Pending' && (
                            <i className="overlay-dom">
                                Pending
                            </i>
                            )}                            
                        </div>
                    )}
                </div>
                <div className="item-description">
                <div className="d-flex justify-content-between mb-3">
                <h4>${slug.ListPrice.toLocaleString()}</h4>
                </div>
                <div className="d-flex justify-content-between mb-3">
                        <span className="item-title"> 
                        <a href={`/cities/${slug.City}/${slug.BedroomsTotal}`}>{slug.BedroomsTotal} bds</a> | &nbsp;
                        <a href={`/cities/${slug.City}/${slug.BedroomsTotal}/${slug.BathroomsTotalInteger}`}>{slug.BathroomsTotalInteger} bths</a> | {slug.BuildingAreaTotal} sqft - {slug.MlsStatus}
                        <br/>  {slug.StreetNumber} {slug.StreetName} {slug.StreetSuffix}, <a href={`/cities/${slug.City}`}>{slug.City}</a>, {slug.StateOrProvince}. {slug.PostalCode}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <div>
                            <i className="fas fa-object-group"></i>&nbsp;
                            <span className="item-title">
                                Subdivision&nbsp; 
                                <Link 
                                    to={`/subdivision/${slug.City}/${slug.SubdivisionName}`} 
                                    className="item-title"
                                    title={`View homes in ${slug.SubdivisionName} subdivision`}                                    
                                >
                                    {slug.SubdivisionName} 
                                </Link>
                            </span>
                        </div>
                        {/* 
                        <Link to={`/home/${slug.StreetNumber}-${slug.StreetName}-${slug.StreetSuffix}-${slug.City}-${slug.StateOrProvince}-${slug.PostalCode}/${slug.ListingKey}`} className="item-title">
                            <button className="btn btn-detail">View</button>
                        </Link>*/}
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                    <span className="item-title">
                        {slug.ListOfficeName}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FlatItem