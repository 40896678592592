import './App.css';
import FlatDetail from "./components/FlatDetail"
import Cities from "./components/Cities"
import Subdivision from "./components/Subdivision"
import Subdivisions from "./components/Subdivisions"
import Header from "./components/Header"
import Footer from "./components/Footer"
import Home from "./components/Home"
import Search from "./components/Search"
import Feature from "./components/Feature"
import OpenHouses from "./components/OpenHouses"
import Rooms from "./components/Rooms"
import SuperLike from "./components/SuperLike"
import Contact from "./components/Contact"
import About from "./components/About"
import City from "./components/City"
import CityDetail from "./components/CityDetail"
import {BrowserRouter as Router,Route} from "react-router-dom";
 

function App() {
  return (
    <Router>
      <div className="App">
        <Header/>
        <Route path="/" exact component={Home}></Route>
        <Route path="/search" exact component={Search}></Route>
        <Route path="/feature/:feature/:city" exact component={Feature}></Route>
        <Route path="/feature/:feature" exact component={Feature}></Route>
        <Route path="/openhouses/:feature" exact component={OpenHouses}></Route>
        <Route path="/rooms/:rooms" exact component={Rooms}></Route>
        <Route path="/superlike/:text" exact component={SuperLike}></Route>
        <Route path="/contact"  exact component={Contact}></Route>
        <Route path="/about"  exact component={About}></Route>
        <Route path="/cities" exact component={City}></Route>
        <Route path="/blog/:id"  exact component={CityDetail}></Route>
        <Route path="/cities/:city/:bedrooms/:bathrooms" exact  component={Cities}></Route>
        <Route path="/cities/:city/:bedrooms" exact  component={Cities}></Route>
        <Route path="/cities/:city"  exact component={Cities}></Route>
        <Route path="/home/:address/:listingKey"  exact component={FlatDetail}></Route>
        <Route path="/home" exact  component={Home}></Route>
        <Route path="/subdivision/:city/:subdivision" exact  component={Subdivision}></Route>
        <Route path="/subdivisions/:city" exact  component={Subdivisions}></Route>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
