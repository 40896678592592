import React from "react"
import { Link } from "react-router-dom"
import { useEffect } from "react"

const Header = () => {
    
    useEffect(() => {
        const handleLinkClick = () => {
            const navbarCollapse = document.getElementById('navbarNav');
            if (navbarCollapse && navbarCollapse.classList.contains('show')) {
                navbarCollapse.classList.remove('show');
            }
        };

        const links = document.querySelectorAll('.navbar-nav .nav-link');
        links.forEach(link => link.addEventListener('click', handleLinkClick));

        return () => {
            links.forEach(link => link.removeEventListener('click', handleLinkClick));
        };
    }, []);

    return (
        <div className="header">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/">
                            <div className="d-flex align-items-center">
                                <span className="ms-2">
                                    <img src="/cr-logo.png" alt="Logo"/><br />
                                    <small className="text-muted" style={{ fontSize: '0.85rem' }}>Powered by Ackee</small>
                                </span>
                            </div>
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">Homes</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/cities">Cities</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="#">Neighborhoods<i className="fas fa-chevron-down"></i></Link>
                                    <ul className="sub-ul">
                                        <li><a href="/subdivisions/Carmel">Carmel Neighborhoods</a></li>
                                        <li><a href="/subdivisions/Westfield">Westfield Neighborhoods</a></li>
                                        <li><a href="/subdivisions/Fishers">Fishers Neighborhoods</a></li>
                                        <li><a href="/subdivisions/Zionsville">Zionsville Neighborhoods</a></li>
                                    </ul>
                                </li>                                  
                                <li className="nav-item">
                                    <Link className="nav-link" to="#">Open Houses<i className="fas fa-chevron-down"></i></Link>
                                    <ul className="sub-ul">
                                        <li><a href="/openhouses/Carmel">Carmel</a></li>
                                        <li><a href="/openhouses/Westfield">Westfield</a></li>
                                        <li><a href="/openhouses/Fishers">Fishers</a></li>
                                        <li><a href="/openhouses/Zionsville">Zionsville</a></li>
                                    </ul>
                                </li>                   
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about">About</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Header;
