import { useEffect, useState } from "react"
import banner from "../banner.jpg"
import { Link } from "react-router-dom";
import AWS from 'aws-sdk';
import { DocumentClient } from "aws-sdk/clients/dynamodb";

var supportedCities = ["Carmel", "Fishers", "Zionsville", "Noblesville", "Westfield"];

const Banner = () => {

    const [selectedCities, setSelectedCities] = useState([]);
    const [queryParam, setQueryParam] = useState('');
    const [search, setSearch] = useState();
    const [find, setFind] = useState([]);
    const [word, setWord] = useState("");
    useEffect(() => {

        findSearchData();
        //setSearch(["a","b","test", "mb","14549 Brackney Lane", "14432 Main Street"])
    }, [])

    const getUniqueLabelNames = async () => {
        AWS.config.region = process.env.REACT_APP_REGION;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
        });

        const docClient = new DocumentClient()
        try {
            let uniqueLabels = new Set();
            let params = {
                TableName: process.env.REACT_APP_IMAGES_TABLE,
                ProjectionExpression: "LabelName"
            };
    
            let items;
            do {
                items = await docClient.scan(params).promise();
                items.Items.forEach(item => {
                    if (item.LabelName) {
                        uniqueLabels.add(item.LabelName);
                    }
                });
                params.ExclusiveStartKey = items.LastEvaluatedKey;
            } while (typeof items.LastEvaluatedKey != "undefined");
    
            return Array.from(uniqueLabels);
        } catch (error) {
            console.error("Error fetching unique LabelNames:", error);
            throw new Error("Error fetching unique LabelNames");
        }
    };

    const getUniqueSubdivisions = async () => {
        AWS.config.region = process.env.REACT_APP_REGION;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
        });

        const docClient = new DocumentClient()
        try {
            let uniqueSubdivisions = []
            let params = {
                TableName: process.env.REACT_APP_PROPERTIES_TABLE,
                ProjectionExpression: "SubdivisionName, City"
            };
    
            let items;
            do {
                items = await docClient.scan(params).promise();
                items.Items.forEach(item => {
                    if (item.SubdivisionName && item.City) {
                        // Use a string concatenation or some unique identifier to ensure uniqueness
                        let key = `${item.SubdivisionName}-${item.City}`;
                        if (!uniqueSubdivisions.some(itemSearch => itemSearch.name === item.SubdivisionName && itemSearch.city === item.City)) {
                            uniqueSubdivisions.push({"name": item.SubdivisionName, "city": item.City});
                        }
                    }
                });
                params.ExclusiveStartKey = items.LastEvaluatedKey;
            } while (typeof items.LastEvaluatedKey != "undefined");
 
            console.log(JSON.stringify(uniqueSubdivisions) + " is the array");
    
            return Array.from(uniqueSubdivisions);
        } catch (error) {
            console.error("Error fetching unique LabelNames:", error);
            throw new Error("Error fetching unique LabelNames");
        }
    };

    const findSearchData = async () => {

        //document.getElementById("address").disabled = true;
        // document.getElementById("address").value = "Please wait";

        AWS.config.region = process.env.REACT_APP_REGION;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
        });

        const params = {
            TableName: process.env.REACT_APP_PROPERTIES_TABLE,
            ProjectionExpression: 'ListingKey, ListPrice,ListOfficeName,DaysOnMarket, BuildingAreaTotal,MlsStatus, BedroomsTotal, BathroomsTotalInteger, StreetNumber, StreetName, StreetSuffix, City, StateOrProvince, PostalCode, SubdivisionName, MBR_LastStatus, Media',
        };

        const docClient = new DocumentClient()
        var addresses = []
/*
        
        let data;
        do {
            data = await docClient.scan(params).promise();

            data.Items.forEach(item => {
                const linkUrl = `/home/${item.StreetNumber}-${item.StreetName}-${item.StreetSuffix}-${item.City}-${item.StateOrProvince}-${item.PostalCode}/${item.ListingKey}`;
                const address = {
                    text: `${item.StreetNumber} ${item.StreetName} ${item.StreetSuffix}`,
                    link: linkUrl
                };
                addresses.push(address);
            });

            // Continue scanning if we have more items
            params.ExclusiveStartKey = data.LastEvaluatedKey;
        } while (typeof data.LastEvaluatedKey !== 'undefined');        
*/
   
        var labels = [];
        getUniqueLabelNames().then(uniqueLabels => {
            console.log("Unique LabelNames:", uniqueLabels);

            // document.getElementById("checkbox-Carmel").checked
            console.log(selectedCities);

            for (const label of uniqueLabels) {
                var linkUrl = `/feature/${label}`                
                var labelObj = {"text": `${label} (property feature)`, "link": linkUrl}       
                addresses.push(labelObj);
            }


        }).catch(error => {
            // console.error("Error:", error);
        });

        /*var subdivisions = [];
        getUniqueSubdivisions().then(uniqueSubdivisions => {
            // console.log("Unique LabelNames:", uniqueSubdivisions);
            console.log(uniqueSubdivisions)
            for (const subdivision of uniqueSubdivisions) {
                var linkUrl = `/subdivision/${subdivision.city}/${subdivision.name}`                
                var subObj = {"text": `${subdivision.name} subdivision`, "link": linkUrl}       
                addresses.push(subObj);
            }


        }).catch(error => {
            // console.error("Error:", error);
        });

        // console.log(labels);
        // console.log(addresses);;*/
        setSearch(addresses)

        // document.getElementById("address").disabled = false;
    }

    const findSearch = (e) => {

            //console.log(document.getElementById("checkbox-Carmel").checked)
            console.log("Searching")
            setWord(e.target.value)


            if (e.target.value.length >= 3) {
                const filteredCountries = search.filter(item => 
                    item.text.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ? item : null
                );
    
                console.log(filteredCountries);
                e.target.value.length === 0 ? setFind([]) : setFind(filteredCountries);
           }
    }


    const findResult = () => {
        if (find.length === 0 && word.length >= 3) {
            return <div className="find-search">Not Found</div>
        }
        if (find.length > 0) {
            return <div className="find-search">
                {
                    find.map(item => {

                        return <Link key={item.text} to={item.link}>{item.text}</Link>
                    })
                }
            </div>
        }
    }
    return (
<div className="banner d-flex align-items-center" style={{ backgroundImage: `url(${banner})` }}>
    <div className="bg-custom">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 mx-auto">
                    <div className="banner-area text-center pt-4 pb-4">
                    <h4 className="mt-2 mb-4"><strong>Homes. Neighborhoods. Features. Open houses</strong> </h4>
                    <p>Search for features such as kitchen, living room, pool, laundry, or lakefront</p>
                        <div className="search-area">
                            <input 
                                id="address"
                                value={word} 
                                onChange={(e) => findSearch(e)} 
                                type="text" 
                                className="inp-search" 
                                placeholder="Enter your search" 
                            />
                        </div>
                        {findResult()}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    )
}

export default Banner;