import SubdivisionsList from "./SubdivisionsList"
import Banner from "./Banner"
import React, { useState, useEffect } from 'react';
import TeamList from "./TeamList"
import References from "./References"
import Subscribe from "./Subscribe"
import BestFlatList from "./BestFlatList"
import AWS from 'aws-sdk';
import { useParams } from 'react-router-dom';

const Subdivisions =()=>{
    const { city } = useParams(); // Extract city parameter from URL

    useEffect(() => {
        console.log(city);
    });

    return (
        <React.Fragment>
            <SubdivisionsList slug={city} />
{/*             <BestFlatList/>
            <Subscribe/>
            <TeamList/>
            <References/>
 */}        </React.Fragment>
    )
}

export default Subdivisions;