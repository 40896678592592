const About = () => {
    return (
        <section className="about">
            <div className="page-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-title">About Ackee</h1>
                            <h2 className="page-description">Ackee.ai artificial intelligence real estate search platform</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src="/img/product1.jpeg" alt="product" className="w-100" />
                        </div>
                        <div className="col-lg-6">
                        <div className="about-item">
                                <div className="title">
                                    Ackee App
                            </div>
                                <div className="about-text">
                                <p>Ackee is a real estate platform and the easiest way to find properties ever!</p>
                                <p>Our innovative real estate platform leverages advanced AI to enhance property searches. It uses image recognition to identify key features in listing photos, such as kitchens and bathrooms, and enables users to search for properties using natural language queries like "find me a 5 bedroom home with a modern kitchen." This seamless integration of AI ensures a more intuitive and efficient home-buying experience.</p>
                                <p>Ackee is built on AWS [Lambda, S3, Dynamo Db, Amazon Rekognition, Simple Queue Service, and Beanstalk] and React</p>
                                <p>Try out the following features</p>
                            </div>
                                <div className="about-features">
                                <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i><a href="/home/729-Mayfair-Lane-Carmel-IN-46032/MBR21972803">View a property</a></p>
                                    <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i><a href="/feature/Kitchen">Search for Kitchen</a></p>
                                    <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i><a href="/feature/Fireplace">Search for FirePlace</a></p>
                                    <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i><a href="/feature/Living Room">Search for Living Room</a></p>
                                    <p className="about-feature"><i className="fas fa-long-arrow-alt-right"></i><a href="/feature/Bathroom">Search for Bathroom</a></p>

                                </div>
                            </div>                            
                            <div className="about-item">
                                <div className="title">
                                    About Ackee (the Fruit)
                            </div>
                                <div className="about-text">
                                    <img src="/ackeedish.jpg"  style={{ width: '320px' }}/>
                                <p>Jamaican ackee is a unique fruit that holds cultural significance and culinary prominence in Jamaica and beyond. Native to West Africa, it was brought to Jamaica in the late 18th century. The ackee fruit is known for its distinctive appearance, with bright red pods that split open when ripe to reveal large, shiny black seeds and creamy yellow flesh. </p>
                                <p>However, only the flesh surrounding the seeds is edible, as the seeds and the rind contain toxic compounds that must be carefully removed during preparation. Once cooked, the flesh of the ackee has a delicate texture and a subtly sweet, buttery flavor. It is a staple ingredient in Jamaican cuisine, particularly in the beloved national dish, ackee and saltfish, where it is sautéed with salted codfish, onions, tomatoes, and spices. </p>
                                <img src="/ackeetree.jpg"  style={{ width: '320px' }}/>
                                <p>Ackee is not only cherished for its delicious taste but also celebrated for its cultural significance, symbolizing resilience, unity, and the vibrant spirit of Jamaican identity.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About