import CityItem from "./CityItem"

const City = () => {
    return (
        <section className="blog">
            <div className="page-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-title">Featured cities of Hamilton County</h1>
                            <p>Hamilton County, Indiana, located in the heart of the Midwest, is a vibrant and rapidly growing area known for its blend of suburban charm, economic vitality, and strong community spirit. Situated just north of Indianapolis, Hamilton County offers a diverse array of attractions and amenities that appeal to residents and visitors alike.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row"> 
                        <CityItem link="blog-1" population="Established in 1837" image="carmel.jpeg" title="Carmel" description="Carmel, Indiana, is a vibrant city located just north of Indianapolis, renowned for its high quality of life, robust economy, and exceptional amenities. Frequently lauded for its livability, Carmel boasts an array of cultural and recreational attractions, including the Palladium at the Center for the Performing Arts and the Carmel Arts & Design District. The city features extensive parks and trails, such as the Monon Trail, offering ample opportunities for outdoor activities. Carmel's well-planned infrastructure includes roundabouts and the innovative Carmel City Center, enhancing its reputation for safety and convenience. Known for its top-rated schools, diverse dining options, and thriving business environment, Carmel seamlessly blends small-town charm with urban sophistication, making it an ideal place to live, work, and visit."/>
                        <CityItem link="blog-2" population="Established in 1852" image="zionsville.jpeg" title="Zionsville" description="Zionsville, Indiana, is a picturesque town located just northwest of Indianapolis, celebrated for its charming village atmosphere, historic downtown, and strong community spirit. Known for its brick-paved Main Street, Zionsville offers a delightful array of boutique shops, art galleries, and locally-owned restaurants, creating a vibrant and welcoming environment. The town is home to numerous parks and green spaces, including the expansive Zionsville Nature Park and the scenic Rail Trail, providing abundant recreational opportunities. Zionsville's excellent schools and safe neighborhoods make it an attractive destination for families. With its blend of historic charm and modern amenities, Zionsville offers a unique and inviting lifestyle, making it a beloved place to live, work, and visit." />
                        <CityItem link="blog-3" population="Established in 1834" image="westfield.jpeg" title="Westfield" description="Westfield, Indiana, is a rapidly growing city located just north of Indianapolis, known for its family-friendly environment, excellent schools, and thriving community. Home to the renowned Grand Park Sports Campus, Westfield is a hub for youth and amateur sports, drawing visitors from across the country. The city features a charming downtown area, with a mix of local shops, restaurants, and cultural attractions, including the Westfield Playhouse. Extensive parks and trails, such as the Monon Trail and Cool Creek Park, offer residents ample opportunities for outdoor activities. With its strategic location, robust economy, and high quality of life, Westfield seamlessly blends small-town charm with modern conveniences, making it an ideal place to live, work, and play."/>
                        <CityItem link="blog-4" population="Established in 1872" image="fishers.jpeg"  title="Fishers" description="Fishers, Indiana, is a dynamic and rapidly growing suburb located northeast of Indianapolis, known for its family-friendly atmosphere, thriving economy, and innovative community initiatives. The city boasts a range of recreational amenities, including the expansive Fishers District, which offers diverse dining, shopping, and entertainment options. Fishers is home to numerous parks, trails, and green spaces, such as the popular Ritchey Woods Nature Preserve and the Nickel Plate District Amphitheater, which hosts concerts and community events. The city places a strong emphasis on education and public safety, making it an attractive destination for families. With its blend of suburban charm, modern amenities, and a strong sense of community, Fishers provides an ideal environment for residents to live, work, and play."/>
                        <CityItem link="blog-5" population="Established in 1823" image="product1.jpeg" title="Noblesville" description="Noblesville, Indiana, is a historic city located northeast of Indianapolis, known for its vibrant downtown, rich history, and strong community spirit. The city offers a blend of cultural attractions, including the Indiana Transportation Museum and the Ruoff Music Center, along with an array of local shops, restaurants, and parks. With excellent schools and family-friendly amenities, Noblesville is a desirable place to live, work, and play."/>                        
                        <CityItem link="blog-6" population="Established in 1861" image="product1.jpeg" title="Arcadia" description="Arcadia, Indiana, is a charming small town situated in the northern part of Hamilton County. Known for its rural charm and tight-knit community, Arcadia features scenic landscapes, historic buildings, and a peaceful atmosphere. The town offers a relaxed pace of life, with local events and traditions that bring residents together. Arcadia is ideal for those seeking a serene environment with a strong sense of community."/>                                                
                        <CityItem link="blog-7" population="Established in 1860" image="product1.jpeg" title="Sheridan" description="Sheridan, Indiana, is a quaint town located in the northwestern corner of Hamilton County, known for its agricultural heritage and close-knit community. The town boasts a rich history, evident in its historic downtown and local museums. Sheridan offers a variety of parks, recreational activities, and community events, making it a welcoming place for families and individuals. With its friendly atmosphere and rural charm, Sheridan provides a peaceful and engaging lifestyle."/>
                                            </div>
                </div>
            </div>
        </section>
    )
}

export default City