import FlatList from "./FlatList";
import Banner from "./Banner";
import React, { useState, useEffect } from 'react';
import TeamList from "./TeamList";
import References from "./References";
import Subscribe from "./Subscribe";
import BestFlatList from "./BestFlatList";
import FlatItem from "./FlatItem"
import AWS from 'aws-sdk';
import { Link } from "react-router-dom";

var searchText = "";

const Search = () => {
    
    const [liked, setLiked] = useState();

    const [propertyImages, setPropertyImages] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [loading, setLoading] = useState(false); // State variable for loading indicator
    const title = {
        text: "Find " + searchValue + " photos with Carmel Realty LLC",
        description: "Properties with" + searchValue + " photos"
    }
    document.title = title.text;
    document.description = title.description;

    const handleLikeClick = () => {
        // Retrieve existing liked properties array from local storage
        /*console.log(slug.liked);
        slug.liked = !slug.liked;

        setLiked(!liked);
        const likedProperties = JSON.parse(localStorage.getItem('likedProperties')) || [];
        if (liked) {
            // Remove property from likedProperties if it was previously liked
            const updatedProperties = likedProperties.filter(property => property.ListingKey !== slug.ListingKey);
            localStorage.setItem('likedProperties', JSON.stringify(updatedProperties));
        } else {
            // Add property to likedProperties if it was not previously liked
            likedProperties.push(slug);
            localStorage.setItem('likedProperties', JSON.stringify(likedProperties));
        }*/

    };

    useEffect(() => {
        
        // Extract address components from URL parameter
        const paramSearch = new URLSearchParams(window.location.search);
        const imageParam = paramSearch.get('text');

        searchText = imageParam;
        document.getElementById("searchInput").value = searchText;
        console.log("You are searching for " + imageParam);
        handleSearch()
    }, []);

    function toProperCase(str) {
        return str.toLowerCase().replace(/(?:^|\s)\w/g, function(match) {
            return match.toUpperCase();
        });
    }

    const handleSearch = async () => {
        console.log("Searching");
        setLoading(true); // Set loading to true before fetching data

        // Call the useEffect hook to re-fetch the data with the new search value
        setSearchValue(document.getElementById("searchInput").value);

        // var inputSearch = toProperCase(document.getElementById("searchInput").value);
        var inputSearch = document.getElementById("searchInput").value;

        if (!inputSearch) {
            inputSearch = searchText;
        }

        console.log(searchText);

        AWS.config.region = process.env.REACT_APP_REGION;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
        });

        const docClient = new AWS.DynamoDB.DocumentClient();

        const params1 = {
            TableName: process.env.REACT_APP_IMAGES_TABLE,
            //IndexName: 'LabelName-index', // Specify the index name
            KeyConditionExpression: 'Label = :label',
            ExpressionAttributeValues: {
                ':label': inputSearch  // Assuming 'inputSearch' is a string
            },
        };

        const params2 = {
            TableName: process.env.REACT_APP_IMAGES_TABLE,
            FilterExpression: 'LabelName = :label',
            ExpressionAttributeValues: {
                ':label': inputSearch  // Assuming 'inputSearch' is a string
            },
        };

        const params = {
            TableName: process.env.REACT_APP_IMAGES_TABLE,
            FilterExpression: 'LabelName = :label', // Convert LabelName to lowercase
            ExpressionAttributeValues: {
                ':label': inputSearch  // Convert inputSearch to lowercase
            },
        };

        console.log(params);

        try {
            const data = await docClient.scan(params).promise();
            
            console.log("Search data is " + data);
            console.log(JSON.stringify(data));
            for (const item of data.Items) {
                console.log("The ListingKey is " + item.ListingKey);

                const propertiesParams = {
                    TableName: process.env.REACT_APP_PROPERTIES_TABLE,
                    Key: {
                        'ListingKey': item.ListingKey
                    },
                };

                console.log(propertiesParams);
                const propertyData = await docClient.get(propertiesParams).promise();

                console.log("PPP" + JSON.stringify(propertyData));
                console.log('Property Data:', propertyData.Item);
                
                item.BedroomsTotal = propertyData.Item.BedroomsTotal;

                if (!item.BedroomsTotal) {
                    item.BedroomsTotal = 0;
                }
                
                item.StreetNumber = propertyData.Item.StreetNumber;
                item.StreetName = propertyData.Item.StreetName;
                item.StreetSuffix = propertyData.Item.StreetSuffix;
                item.City = propertyData.Item.City;
                item.StateOrProvince = propertyData.Item.StateOrProvince;
                item.PostalCode = propertyData.Item.PostalCode;

            }

            data.Items.sort((a, b) => b.BedroomsTotal - a.BedroomsTotal);
            //setPropertyImages(data.Items);

            setPropertyImages(data.Items);

            // console.log('Scan succeeded. Data:', data);
        } catch (err) {
            console.error('Unable to scan the table. Error:', err);
        }finally {
            setLoading(false); // Set loading back to false after fetching data
        }
    };

    return (
        <React.Fragment>
            <section className="section-subscribe pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <p className="title">Search</p>
                            <span className="sbs-description">The easiest way to find properties ever!<br/>Search for Bathroom, Fireplace,Gas Stove, Pool, Grand Piano </span>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-flex align-items-center justify-content-between w-100 h-100">
                                <div className="row w-100 m-0">
                                    <div className="col-lg-9">
                                        <input type="text" id="searchInput" className="w-100 sbs-area-inp" />
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-between ">
                                        <input type="submit" onClick={handleSearch} className="w-100 h-100 btn btn-dark" value="Search" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-all-re">
                <div className="container">
                {loading && <p>Searching Ackee  for {searchValue} (Please wait)...</p>}
                    <div className="row row-cols-1 row-cols-md-1 row-cols-lg-4">
                        {propertyImages.map((image, index) => (
                            <div key={index} className="col image-container">
                                
                                <a href={`/home/${image.StreetNumber}-${image.StreetName}-${image.StreetSuffix}-${image.City}-${image.StateOrProvince}-${image.PostalCode}/${image.ListingKey}?image=${image.S3ImageName}`}>
                                <img 
                                    src={`https://mls-property-media-prod-v1.s3.us-east-2.amazonaws.com/thumbnails/${image.S3ImageName}`} 
                                    alt={`Image ${index}`} 
                                    className="img-fluid"
                                /><br/>
                                <i className={liked ? "fas fa-heart overlay-heart" : "far fa-heart overlay-heart-one"} id="likeButton" onClick={handleLikeClick}></i>

                                <span className="item-title">{image.BedroomsTotal} bds |  {image.StreetNumber} {image.StreetName} {image.StreetSuffix} {image.City}, {image.StateOrProvince}. {image.PostalCode}</span>
                                </a>                                        
                            </div>
                        ))}
                    </div>
                </div>
            </section>

        </React.Fragment>
    )
}

export default Search;
